// src/components/dashboard/DashboardTabs.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Rocket,
    Book,
    Users,
    Bot,
    Code
} from 'lucide-react';
import { Alert } from '@mui/material';
import {
    fetchUserProfile,
    fetchUserAchievements,
    fetchUserBadges,
    fetchActiveSandboxes,
    fetchEnrollments,
    fetchComponentTypes
} from '../../services/apiService';
import DashboardMainTab from './tabs/DashboardMainTab';
import DashboardSandboxesTab from './tabs/DashboardSandboxesTab'
import DashboardAPITab from './tabs/DashboardAPITab';
import DashboardAITutorsTab from './tabs/DashboardAITutorsTab';

const DashboardTabs = ({ userInfo, token }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('dashboard');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Данные для всех вкладок
    const [profile, setProfile] = useState(null);
    const [achievements, setAchievements] = useState([]);
    const [badges, setBadges] = useState([]);
    const [enrollments, setEnrollments] = useState([]);
    const [mySandboxes, setMySandboxes] = useState([]);
    const [componentTypes, setComponentTypes] = useState([]);

    // Загрузка общих данных при монтировании
    useEffect(() => {
        const loadDashboardData = async () => {
            if (!token || !userInfo) return;

            setIsLoading(true);
            setError(null);

            try {
                const [
                    profileData,
                    achievementsData,
                    badgesData,
                    mySandboxesData,
                    enrollmentsData,
                    componentTypesData
                ] = await Promise.all([
                    fetchUserProfile(token, userInfo.id),
                    fetchUserAchievements(token),
                    fetchUserBadges(token),
                    fetchActiveSandboxes(token, userInfo.id, 'owner'),
                    fetchEnrollments(token),
                    fetchComponentTypes(token)
                ]);

                setProfile(profileData);
                setAchievements(achievementsData);
                setBadges(badgesData);
                setMySandboxes(mySandboxesData);
                setEnrollments(enrollmentsData);
                setComponentTypes(componentTypesData);

                setIsLoading(false);
            } catch (err) {
                setError(t('dashboard.error.loading_data'));
                console.error('Error loading dashboard data:', err);
                setIsLoading(false);
            }
        };

        // Добавляем обработку промиса для предотвращения ошибки "Promise returned is ignored"
        loadDashboardData().catch(error => {
            console.error("Unhandled error in loadDashboardData:", error);
            setError(t('dashboard.error.loading_data'));
            setIsLoading(false);
        });
    }, [token, userInfo, t]);

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-pulse text-gray-500">{t('dashboard.loading')}</div>
            </div>
        );
    }

    // Типизированная функция для отображения вкладок
    const renderActiveTab = () => {
        switch (activeTab) {
            case 'dashboard':
                return (
                    <DashboardMainTab
                        userInfo={userInfo}
                        token={token}
                        profile={profile}
                        achievements={achievements}
                        badges={badges}
                        mySandboxes={mySandboxes}
                        enrollments={enrollments}
                        componentTypes={componentTypes}
                        setMySandboxes={setMySandboxes}
                        error={error}
                        setError={setError}
                    />
                );
            case 'sandboxes':
                return (
                    <DashboardSandboxesTab
                        userInfo={userInfo}
                        token={token}
                        setError={setError}
                    />
                );
            case 'courses':
                return (
                    <div>{t('dashboard.tabs.coming_soon')}</div>
                );
            case 'ai-tutors':
                return (
                    <DashboardAITutorsTab
                        token={token}
                        setError={setError}
                    />
                );
            case 'groups':
                return (
                    <div>{t('dashboard.tabs.coming_soon')}</div>
                );
            case 'api':
                return (
                    <DashboardAPITab
                        token={token}
                        setError={setError}
                    />
                );
            default:
                return <div>{t('dashboard.tabs.coming_soon')}</div>;
        }
    };

    return (
        <div className="min-h-screen w-full bg-gray-50">
            <div className="pt-4 pb-8">
                {error && (
                    <Alert severity="error" className="max-w-7xl mx-auto px-4 mb-4" onClose={() => setError(null)}>
                        {error}
                    </Alert>
                )}

                {/* Вкладки */}
                <div className="max-w-7xl mx-auto px-4">
                    <div className="border-b border-gray-200 mb-6">
                        <nav className="flex -mb-px space-x-8">
                            <button
                                onClick={() => setActiveTab('dashboard')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'dashboard'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {t('dashboard.tabs.main')}
                            </button>
                            <button
                                onClick={() => setActiveTab('sandboxes')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'sandboxes'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                <span className="flex items-center gap-1">
                  <Rocket className="w-4 h-4" />
                    {t('dashboard.tabs.sandboxes')}
                </span>
                            </button>
                            <button
                                onClick={() => setActiveTab('courses')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'courses'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                <span className="flex items-center gap-1">
                  <Book className="w-4 h-4" />
                    {t('dashboard.tabs.courses')}
                </span>
                            </button>
                            <button
                                onClick={() => setActiveTab('ai-tutors')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'ai-tutors'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                <span className="flex items-center gap-1">
                  <Bot className="w-4 h-4" />
                    {t('dashboard.tabs.ai_tutors')}
                </span>
                            </button>
                            <button
                                onClick={() => setActiveTab('groups')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'groups'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                <span className="flex items-center gap-1">
                  <Users className="w-4 h-4" />
                    {t('dashboard.tabs.groups')}
                </span>
                            </button>
                            <button
                                onClick={() => setActiveTab('api')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'api'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                <span className="flex items-center gap-1">
                  <Code className="w-4 h-4" />
                    {t('dashboard.tabs.api')}
                </span>
                            </button>
                        </nav>
                    </div>

                    {/* Содержимое вкладок */}
                    <div className="mb-8">
                        {renderActiveTab()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardTabs;