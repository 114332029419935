// src/components/dashboard/tabs/AITutors/TutorDetailsDialog.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    Tabs,
    Tab,
    Box,
    Typography,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    Tooltip,
    Divider,
    Paper,
    Chip
} from '@mui/material';
import {
    Bot,
    Save,
    Edit,
    Book,
    Activity,
    FileText,
    Upload,
    Trash2,
    Clock,
    X,
    AlertCircle,
    Server
} from 'lucide-react';
import {
    updateAITutor,
    uploadAITutorMaterial,
    fetchAITutorDetails,
    fetchSandboxInstancesForTutor
} from '../../../services/apiService';

const TutorDetailsDialog = ({ open, onClose, tutor, token, setError, onUpdate }) => {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);

    // Состояния формы
    const [formData, setFormData] = useState({
        name: tutor.name || '',
        instructions: tutor.instructions || '',
        instruction_type: tutor.instruction_type || 'assistant',
        sandbox_instance: tutor.sandbox_instance || null,
        archive: tutor.archive || false
    });

    // Состояния для материалов
    const [materials, setMaterials] = useState(tutor.materials || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [totalMaterialSize, setTotalMaterialSize] = useState(0);

    // Состояния UI
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [sandboxInstances, setSandboxInstances] = useState([]);
    const [loadingSandboxes, setLoadingSandboxes] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    // Константы
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 МБ
    const MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50 МБ на все файлы
    const ALLOWED_FILE_TYPES = ['text/plain', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/zip'];
    const ALLOWED_FILE_EXTENSIONS = ['.txt', '.pdf', '.docx', '.zip'];

    // Загрузка данных при открытии диалога
    useEffect(() => {
        if (open) {
            loadSandboxInstances();
            refreshTutorData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, tutor.id, token]);

    // Расчет суммарного размера материалов
    useEffect(() => {
        // Здесь можно было бы посчитать размер материалов,
        // но в API нет поля с размером, поэтому это будет псевдо-расчет
        // На самом деле нужно будет контролировать это на бэкенде
        setTotalMaterialSize(materials.length * 1024 * 1024); // Псевдо-размер: 1 МБ на материал
    }, [materials]);

    // Загрузка инстансов песочниц
    const loadSandboxInstances = async () => {
        setLoadingSandboxes(true);
        try {
            const instances = await fetchSandboxInstancesForTutor(token);
            setSandboxInstances(instances);
        } catch (err) {
            console.error('Error loading sandbox instances:', err);
        } finally {
            setLoadingSandboxes(false);
        }
    };

    // Обновление данных о тьюторе
    const refreshTutorData = async () => {
        setRefreshing(true);
        try {
            const refreshedTutor = await fetchAITutorDetails(token, tutor.id);
            setMaterials(refreshedTutor.materials || []);

            // Обновляем formData только при первоначальной загрузке
            if (!formData.name) {
                setFormData({
                    name: refreshedTutor.name || '',
                    instructions: refreshedTutor.instructions || '',
                    instruction_type: refreshedTutor.instruction_type || 'assistant',
                    sandbox_instance: refreshedTutor.sandbox_instance || null,
                    archive: refreshedTutor.archive || false
                });
            }
        } catch (err) {
            console.error('Error refreshing tutor data:', err);
            setError(t('dashboard.ai_tutors.error.refreshing'));
        } finally {
            setRefreshing(false);
        }
    };

    // Обработчик изменения вкладок
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Обработчик изменения полей формы
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Валидация формы
    const validateForm = () => {
        if (!formData.name.trim()) {
            setFormError(t('dashboard.ai_tutors.error.name_required'));
            return false;
        }
        if (!formData.instructions.trim()) {
            setFormError(t('dashboard.ai_tutors.error.instructions_required'));
            return false;
        }
        return true;
    };

    // Обработчик сохранения изменений
    const handleSave = async () => {
        if (!validateForm()) return;

        setLoading(true);
        setFormError('');

        try {
            const tutorData = {
                ...formData,
                // Преобразуем пустую строку в null для sandbox_instance
                sandbox_instance: formData.sandbox_instance || null
            };

            const updatedTutor = await updateAITutor(token, tutor.id, tutorData);

            if (onUpdate) {
                onUpdate(updatedTutor);
            }

            // Показываем успешное сообщение или закрываем диалог
            handleClose();
        } catch (err) {
            console.error('Error updating AI Tutor:', err);
            setFormError(t('dashboard.ai_tutors.error.updating'));
        } finally {
            setLoading(false);
        }
    };

    // Обработчик выбора файла для загрузки
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadError('');

        if (!file) return;

        // Проверка типа файла
        const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        const isAllowedType = ALLOWED_FILE_TYPES.includes(file.type) ||
            ALLOWED_FILE_EXTENSIONS.includes(fileExtension);

        if (!isAllowedType) {
            setUploadError(t('dashboard.ai_tutors.error.file_type'));
            setSelectedFile(null);
            return;
        }

        // Проверка размера файла
        if (file.size > MAX_FILE_SIZE) {
            setUploadError(t('dashboard.ai_tutors.error.file_too_large'));
            setSelectedFile(null);
            return;
        }

        // Проверка суммарного размера всех материалов
        if (totalMaterialSize + file.size > MAX_TOTAL_SIZE) {
            setUploadError(t('dashboard.ai_tutors.error.total_size_exceeded'));
            setSelectedFile(null);
            return;
        }

        setSelectedFile(file);
    };

    // Обработчик загрузки файла
    const handleUploadFile = async () => {
        if (!selectedFile) return;

        setIsUploading(true);
        setUploadProgress(0);
        setUploadError('');

        try {
            // В реальном приложении здесь можно добавить отслеживание прогресса загрузки
            // Имитируем прогресс
            const progressInterval = setInterval(() => {
                setUploadProgress(prev => {
                    if (prev >= 90) {
                        clearInterval(progressInterval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 300);

            // Загружаем файл
            const uploadedMaterial = await uploadAITutorMaterial(token, tutor.id, selectedFile);

            clearInterval(progressInterval);
            setUploadProgress(100);

            // Добавляем загруженный материал в список
            setMaterials(prev => [...prev, uploadedMaterial]);

            // Сбрасываем выбранный файл
            setSelectedFile(null);

            // Обновляем данные тьютора
            setTimeout(() => {
                refreshTutorData();
                setIsUploading(false);
                setUploadProgress(0);
            }, 500);

        } catch (err) {
            console.error('Error uploading material:', err);
            setUploadError(t('dashboard.ai_tutors.error.uploading'));
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    // Обработчик удаления материала
    const handleDeleteMaterial = async (materialId) => {
        // В данной реализации функция удаления материала не существует в API
        // Нужно будет добавить на бэкенде эндпоинт для удаления материала

        // Имитируем удаление из локального состояния
        setMaterials(prev => prev.filter(material => material.id !== materialId));
    };

    // Обработчик закрытия диалога
    const handleClose = () => {
        if (!loading && !isUploading) {
            onClose();
        }
    };

    // Форматирование размера файла
    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <Bot className="text-purple-500" />
                    {t('dashboard.ai_tutors.details_dialog.title', { name: tutor.name })}
                </div>
                <IconButton onClick={handleClose} size="small">
                    <X className="w-5 h-5" />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    className="mb-4"
                >
                    <Tab
                        icon={<Edit className="w-4 h-4 mr-2" />}
                        label={t('dashboard.ai_tutors.details_dialog.tabs.settings')}
                        iconPosition="start"
                    />
                    <Tab
                        icon={<FileText className="w-4 h-4 mr-2" />}
                        label={t('dashboard.ai_tutors.details_dialog.tabs.materials')}
                        iconPosition="start"
                    />
                </Tabs>

                {/* Вкладка настроек */}
                {tabValue === 0 && (
                    <Box>
                        {formError && (
                            <Alert severity="error" className="mb-4">
                                <AlertTitle>{t('dashboard.ai_tutors.error.title')}</AlertTitle>
                                {formError}
                            </Alert>
                        )}

                        <div className="space-y-4">
                            {/* Имя тьютора */}
                            <TextField
                                name="name"
                                label={t('dashboard.ai_tutors.details_dialog.name')}
                                value={formData.name}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />

                            {/* Тип тьютора */}
                            <FormControl fullWidth>
                                <InputLabel id="instruction-type-label">
                                    {t('dashboard.ai_tutors.details_dialog.type')}
                                </InputLabel>
                                <Select
                                    labelId="instruction-type-label"
                                    name="instruction_type"
                                    value={formData.instruction_type || 'assistant'}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    label={t('dashboard.ai_tutors.details_dialog.type')}
                                >
                                    <MenuItem value="assistant">
                                        <div className="flex items-center gap-2">
                                            <Book className="text-blue-500 w-4 h-4" />
                                            {t('dashboard.ai_tutors.type.assistant')}
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="feedback">
                                        <div className="flex items-center gap-2">
                                            <Activity className="text-green-500 w-4 h-4" />
                                            {t('dashboard.ai_tutors.type.feedback')}
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            {/* Инструкции */}
                            <TextField
                                name="instructions"
                                label={
                                    formData.instruction_type === 'feedback'
                                        ? t('dashboard.ai_tutors.details_dialog.feedback_instructions')
                                        : t('dashboard.ai_tutors.details_dialog.assistant_instructions')
                                }
                                value={formData.instructions}
                                onChange={handleInputChange}
                                multiline
                                rows={6}
                                fullWidth
                                required
                            />

                            {/* Выбор песочницы */}
                            <FormControl fullWidth>
                                <InputLabel id="sandbox-instance-label">
                                    {t('dashboard.ai_tutors.details_dialog.sandbox')}
                                </InputLabel>
                                <Select
                                    labelId="sandbox-instance-label"
                                    name="sandbox_instance"
                                    variant="outlined"
                                    value={formData.sandbox_instance || ''}
                                    onChange={handleInputChange}
                                    label={t('dashboard.ai_tutors.details_dialog.sandbox')}
                                    disabled={loadingSandboxes}
                                    startAdornment={
                                        loadingSandboxes && (
                                            <CircularProgress size={20} className="mr-2" />
                                        )
                                    }
                                >
                                    <MenuItem value="">
                                        {t('dashboard.ai_tutors.details_dialog.no_sandbox')}
                                    </MenuItem>
                                    {sandboxInstances.map((instance) => (
                                        <MenuItem key={instance.id} value={instance.id}>
                                            <div className="flex items-center gap-2">
                                                <Server className="text-blue-500 w-4 h-4" />
                                                {instance.sandbox
                                                    ? `${instance.sandbox} (${instance.namespace})`
                                                    : instance.namespace
                                                }
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                )}

                {/* Вкладка материалов */}
                {tabValue === 1 && (
                    <Box>
                        <div className="mb-4">
                            <Typography variant="subtitle1" className="mb-2">
                                {t('dashboard.ai_tutors.details_dialog.upload_materials')}
                            </Typography>

                            <Paper className="p-4 border border-gray-200">
                                <div className="flex flex-col gap-3">
                                    <div className="flex items-center gap-2">
                                        <input
                                            type="file"
                                            id="material-upload"
                                            className="hidden"
                                            onChange={handleFileChange}
                                            accept=".txt,.pdf,.docx,.zip"
                                            disabled={isUploading}
                                        />
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            htmlFor="material-upload"
                                            disabled={isUploading}
                                            startIcon={<Upload className="w-4 h-4" />}
                                        >
                                            {t('dashboard.ai_tutors.details_dialog.select_file')}
                                        </Button>
                                        {selectedFile && (
                                            <Typography variant="body2" className="ml-2">
                                                {selectedFile.name} ({formatFileSize(selectedFile.size)})
                                            </Typography>
                                        )}
                                    </div>

                                    {selectedFile && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleUploadFile}
                                            disabled={isUploading}
                                            className="bg-purple-600 hover:bg-purple-700"
                                        >
                                            {isUploading ? (
                                                <div className="flex items-center gap-2">
                                                    <CircularProgress size={20} />
                                                    {t('dashboard.ai_tutors.details_dialog.uploading')} {uploadProgress}%
                                                </div>
                                            ) : (
                                                t('dashboard.ai_tutors.details_dialog.upload')
                                            )}
                                        </Button>
                                    )}

                                    {uploadError && (
                                        <Alert severity="error" className="mt-2">
                                            {uploadError}
                                        </Alert>
                                    )}

                                    <Divider className="my-2" />

                                    <Typography variant="body2" color="textSecondary">
                                        {t('dashboard.ai_tutors.details_dialog.file_requirements')}
                                    </Typography>

                                    <div className="flex items-center gap-2">
                                        <Chip
                                            label={t('dashboard.ai_tutors.details_dialog.max_file_size', { size: '50 MB' })}
                                            size="small"
                                            icon={<AlertCircle className="w-3.5 h-3.5" />}
                                        />
                                        <Chip
                                            label={t('dashboard.ai_tutors.details_dialog.allowed_types', { types: '.txt, .pdf, .docx, .zip' })}
                                            size="small"
                                            icon={<AlertCircle className="w-3.5 h-3.5" />}
                                        />
                                    </div>

                                    <div className="flex items-center">
                                        <Typography variant="body2">
                                            {t('dashboard.ai_tutors.details_dialog.storage_usage')}:
                                        </Typography>
                                        <div className="flex-grow mx-4">
                                            <div className="h-2 bg-gray-200 rounded-full">
                                                <div
                                                    className="h-2 bg-purple-500 rounded-full"
                                                    style={{ width: `${Math.min(100, (totalMaterialSize / MAX_TOTAL_SIZE) * 100)}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <Typography variant="body2">
                                            {formatFileSize(totalMaterialSize)} / {formatFileSize(MAX_TOTAL_SIZE)}
                                        </Typography>
                                    </div>
                                </div>
                            </Paper>
                        </div>

                        <Typography variant="subtitle1" className="mt-4 mb-2">
                            {t('dashboard.ai_tutors.details_dialog.materials_list')}
                        </Typography>

                        {refreshing ? (
                            <div className="flex justify-center p-6">
                                <CircularProgress />
                            </div>
                        ) : materials.length === 0 ? (
                            <Alert severity="info">
                                {t('dashboard.ai_tutors.details_dialog.no_materials')}
                            </Alert>
                        ) : (
                            <List>
                                {materials.map((material) => {
                                    const fileExtension = material.document_id.substring(material.document_id.lastIndexOf('.') + 1).toLowerCase();
                                    let fileIcon;

                                    switch(fileExtension) {
                                        case 'pdf':
                                            fileIcon = <FileText className="text-red-500" />;
                                            break;
                                        case 'docx':
                                            fileIcon = <FileText className="text-blue-500" />;
                                            break;
                                        case 'zip':
                                            fileIcon = <FileText className="text-yellow-500" />;
                                            break;
                                        default:
                                            fileIcon = <FileText className="text-gray-500" />;
                                    }

                                    return (
                                        <ListItem
                                            key={material.id}
                                            divider
                                            secondaryAction={
                                                <Tooltip title={t('dashboard.ai_tutors.details_dialog.delete_material')}>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => handleDeleteMaterial(material.id)}
                                                        color="error"
                                                    >
                                                        <Trash2 className="w-4 h-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        >
                                            <ListItemIcon>
                                                {fileIcon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    material.metadata && material.metadata.filename
                                                        ? material.metadata.filename
                                                        : material.document_id
                                                }
                                                secondary={
                                                    <div className="flex items-center gap-2 text-xs text-gray-500">
                                                        <Clock className="w-3 h-3" />
                                                        {new Date(material.uploaded_at).toLocaleString()}
                                                    </div>
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    disabled={loading || isUploading}
                >
                    {t('dashboard.ai_tutors.details_dialog.close')}
                </Button>
                {tabValue === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={loading}
                        className="bg-purple-600 hover:bg-purple-700"
                        startIcon={loading ? <CircularProgress size={20} /> : <Save />}
                    >
                        {loading
                            ? t('dashboard.ai_tutors.details_dialog.saving')
                            : t('dashboard.ai_tutors.details_dialog.save')
                        }
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TutorDetailsDialog;