// src/components/dashboard/tabs/DashboardAITutorsTab.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TextField,
    InputAdornment,
    CircularProgress,
    Button,
    Typography,
    Alert,
    Chip,
    Tabs,
    Tab,
    Box
} from '@mui/material';
import {
    Bot,
    Search,
    Plus,
    Book,
    Activity
} from 'lucide-react';
import { fetchUserAITutors } from '../../../services/apiService';
import TutorCard from './TutorCard';
import CreateTutorDialog from './CreateTutorDialog';

const DashboardAITutorsTab = ({ token, setError }) => {
    const { t } = useTranslation();

    // State для данных
    const [tutors, setTutors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [tabValue, setTabValue] = useState(0); // 0 - все тьюторы, 1 - ассистенты, 2 - проверяющие

    // State для диалогов
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    // Загрузка тьюторов
    useEffect(() => {
        loadTutors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const loadTutors = async () => {
        setIsLoading(true);
        try {
            const data = await fetchUserAITutors(token);
            setTutors(data);
        } catch (err) {
            setError(t('dashboard.ai_tutors.error.loading'));
            console.error('Error loading AI Tutors:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Обработчик создания нового тьютора
    const handleCreateTutor = async (newTutor) => {
        try {
            // Новый тьютор добавляется через CreateTutorDialog
            // и возвращается в этот обработчик
            setTutors([...tutors, newTutor]);
        } catch (err) {
            setError(t('dashboard.ai_tutors.error.creating'));
            console.error('Error finalizing tutor creation:', err);
        }
    };

    // Обработчик удаления тьютора
    const handleDeleteTutor = (tutorId) => {
        setTutors(prev => prev.filter(tutor => tutor.id !== tutorId));
    };

    // Получение отфильтрованных тьюторов
    const getFilteredTutors = () => {
        // Сначала фильтруем по поисковому запросу
        let filtered = tutors;

        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            filtered = filtered.filter(tutor =>
                tutor.name.toLowerCase().includes(term)
            );
        }

        // Затем фильтруем по типу (вкладке)
        if (tabValue === 1) { // Ассистенты
            filtered = filtered.filter(tutor =>
                !tutor.instruction_type || tutor.instruction_type === 'assistant'
            );
        } else if (tabValue === 2) { // Проверяющие задания
            filtered = filtered.filter(tutor =>
                tutor.instruction_type === 'feedback'
            );
        }

        return filtered;
    };

    // Подсчет количества каждого типа тьюторов для бейджей
    const tutorCounts = {
        all: tutors.length,
        assistants: tutors.filter(tutor => !tutor.instruction_type || tutor.instruction_type === 'assistant').length,
        feedback: tutors.filter(tutor => tutor.instruction_type === 'feedback').length
    };

    // Фильтрованные тьюторы для отображения
    const filteredTutors = getFilteredTutors();

    return (
        <div className="space-y-6">
            {/* Панель поиска и фильтрации */}
            <div className="flex flex-col md:flex-row gap-4 items-center mb-6">
                <TextField
                    placeholder={t('dashboard.ai_tutors.search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="flex-grow"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search className="text-gray-400" />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    fullWidth
                />

                <Button
                    onClick={() => setCreateDialogOpen(true)}
                    variant="contained"
                    className="bg-purple-600 hover:bg-purple-700 text-white"
                    startIcon={<Plus />}
                >
                    {t('dashboard.ai_tutors.create_button')}
                </Button>
            </div>

            {/* Вкладки */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(newValue)}
                    variant="fullWidth"
                >
                    <Tab
                        icon={<Bot className="w-4 h-4 mr-2 text-purple-500" />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.ai_tutors.filters.all')}
                                <Chip
                                    label={tutorCounts.all}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#f3e5f5', color: '#7b1fa2' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                    <Tab
                        icon={<Book className="w-4 h-4 mr-2 text-blue-500" />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.ai_tutors.filters.assistants')}
                                <Chip
                                    label={tutorCounts.assistants}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#e3f2fd', color: '#1976d2' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                    <Tab
                        icon={<Activity className="w-4 h-4 mr-2 text-green-500" />}
                        label={
                            <Box display="flex" alignItems="center">
                                {t('dashboard.ai_tutors.filters.feedback')}
                                <Chip
                                    label={tutorCounts.feedback}
                                    size="small"
                                    className="ml-2"
                                    sx={{ backgroundColor: '#e8f5e9', color: '#2e7d32' }}
                                />
                            </Box>
                        }
                        iconPosition="start"
                    />
                </Tabs>

                {/* Контент */}
                <div className="p-4">
                    {isLoading ? (
                        <div className="flex flex-col items-center justify-center py-12">
                            <CircularProgress />
                            <Typography variant="body1" className="mt-4 text-gray-600">
                                {t('dashboard.ai_tutors.loading')}
                            </Typography>
                        </div>
                    ) : filteredTutors.length === 0 ? (
                        <Alert severity="info" className="my-6">
                            {searchTerm
                                ? t('dashboard.ai_tutors.no_results')
                                : t('dashboard.ai_tutors.no_tutors')}
                        </Alert>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {filteredTutors.map(tutor => (
                                <TutorCard
                                    key={tutor.id}
                                    tutor={tutor}
                                    token={token}
                                    onDelete={handleDeleteTutor}
                                    setError={setError}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Диалог создания тьютора */}
            <CreateTutorDialog
                open={createDialogOpen}
                onClose={() => setCreateDialogOpen(false)}
                onSubmit={handleCreateTutor}
                token={token}
                setError={setError}
            />
        </div>
    );
};

export default DashboardAITutorsTab;