// src/components/dashboard/tabs/AITutors/ChatDialog.js
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    IconButton,
    Typography,
    CircularProgress,
    Paper,
    Divider,
    Avatar
} from '@mui/material';
import {
    Bot,
    Send,
    User,
    X,
    Clock,
    RefreshCw
} from 'lucide-react';
import {
    createAITutorConversation,
    sendMessageToAITutor
} from '../../../services/apiService';

const ChatDialog = ({ open, onClose, tutor, token, setError }) => {
    const { t } = useTranslation();
    const messagesEndRef = useRef(null);

    // Состояния чата
    const [message, setMessage] = useState('');
    const [currentConversation, setCurrentConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);

    // Загрузка списка бесед при открытии диалога
    useEffect(() => {
        if (open) {
            // Создаем новую беседу при открытии диалога
            handleCreateConversation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, token]);

    // Скролл к последнему сообщению
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    // Функция создания новой беседы
    const handleCreateConversation = async () => {
        setLoading(true);
        try {
            // Создаем новую беседу
            const newConversation = await createAITutorConversation(token, `Беседа с ${tutor.name}`);
            setCurrentConversation(newConversation);

            // Устанавливаем пустую историю сообщений
            setMessages([]);
        } catch (err) {
            console.error('Error creating conversation:', err);
            setError(t('dashboard.ai_tutors.error.creating_conversation'));
        } finally {
            setLoading(false);
        }
    };

    // Функция отправки сообщения
    const handleSendMessage = async () => {
        if (!message.trim() || !currentConversation) return;

        setSendingMessage(true);
        try {
            // Добавляем сообщение пользователя в список
            const userMessage = {
                id: Date.now(),
                role: 'user',
                content: message,
                timestamp: new Date().toISOString()
            };

            setMessages(prev => [...prev, userMessage]);

            // Отправляем сообщение тьютору
            const response = await sendMessageToAITutor(token, tutor.id, {
                question: message,
                conversation_id: currentConversation.id,
                language: 'ru'
            });

            // Добавляем ответ тьютора в список сообщений
            const assistantMessage = {
                id: Date.now() + 1,
                role: 'assistant',
                content: response.response,
                timestamp: new Date().toISOString()
            };

            setMessages(prev => [...prev, assistantMessage]);

            // Очищаем поле ввода
            setMessage('');
        } catch (err) {
            console.error('Error sending message:', err);
            setError(t('dashboard.ai_tutors.error.sending_message'));
        } finally {
            setSendingMessage(false);
        }
    };

    // Обработчик нажатия Enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Функция форматирования времени
    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { height: '80vh', display: 'flex', flexDirection: 'column' }
            }}
        >
            <DialogTitle className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <Bot className="text-purple-500" />
                    {t('dashboard.ai_tutors.chat_dialog.title', { name: tutor.name })}
                </div>
                <IconButton onClick={onClose} size="small">
                    <X className="w-5 h-5" />
                </IconButton>
            </DialogTitle>

            <Divider />

            <DialogContent className="flex-grow p-0 flex flex-col overflow-hidden">
                {/* Список сообщений */}
                <div className="flex-grow overflow-y-auto p-4">
                    {loading ? (
                        <div className="h-full flex items-center justify-center">
                            <CircularProgress />
                        </div>
                    ) : messages.length === 0 ? (
                        <div className="h-full flex flex-col items-center justify-center text-gray-500">
                            <Bot className="w-12 h-12 mb-4 text-purple-200" />
                            <Typography variant="body1">
                                {t('dashboard.ai_tutors.chat_dialog.start_conversation')}
                            </Typography>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {messages.map((msg) => (
                                <div
                                    key={msg.id}
                                    className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div className={`flex items-start gap-2 max-w-[70%]`}>
                                        {msg.role === 'assistant' && (
                                            <Avatar className="bg-purple-100 text-purple-600">
                                                <Bot className="w-5 h-5" />
                                            </Avatar>
                                        )}

                                        <Paper
                                            elevation={1}
                                            className={`p-3 rounded-lg ${
                                                msg.role === 'user'
                                                    ? 'bg-blue-100 text-blue-900'
                                                    : 'bg-gray-100 text-gray-900'
                                            }`}
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: msg.content.replace(/\n/g, '<br>') }} />
                                            <div className="flex items-center justify-end gap-1 mt-1 text-xs text-gray-500">
                                                <Clock className="w-3 h-3" />
                                                {formatTime(msg.timestamp)}
                                            </div>
                                        </Paper>

                                        {msg.role === 'user' && (
                                            <Avatar className="bg-blue-100 text-blue-600">
                                                <User className="w-5 h-5" />
                                            </Avatar>
                                        )}
                                    </div>
                                </div>
                            ))}

                            {/* Индикатор печати */}
                            {sendingMessage && (
                                <div className="flex justify-start">
                                    <div className="flex items-start gap-2 max-w-[70%]">
                                        <Avatar className="bg-purple-100 text-purple-600">
                                            <Bot className="w-5 h-5" />
                                        </Avatar>
                                        <Paper elevation={1} className="p-3 rounded-lg bg-gray-100">
                                            <div className="flex items-center gap-1">
                                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                            )}

                            <div ref={messagesEndRef} />
                        </div>
                    )}
                </div>

                {/* Форма отправки сообщения */}
                <div className="p-4 bg-gray-50">
                    <div className="flex gap-2">
                        <TextField
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder={t('dashboard.ai_tutors.chat_dialog.message_placeholder')}
                            fullWidth
                            multiline
                            maxRows={4}
                            disabled={sendingMessage || !currentConversation}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendMessage}
                            disabled={!message.trim() || sendingMessage || !currentConversation}
                            className="bg-purple-600 hover:bg-purple-700"
                        >
                            {sendingMessage ? (
                                <CircularProgress size={24} />
                            ) : (
                                <Send />
                            )}
                        </Button>
                    </div>

                    <div className="flex justify-between items-center mt-2">
                        <Typography variant="caption" color="textSecondary">
                            {currentConversation
                                ? t('dashboard.ai_tutors.chat_dialog.conversation_active')
                                : t('dashboard.ai_tutors.chat_dialog.creating_conversation')
                            }
                        </Typography>
                        <Button
                            size="small"
                            startIcon={<RefreshCw className="w-3 h-3" />}
                            onClick={handleCreateConversation}
                            disabled={loading}
                        >
                            {t('dashboard.ai_tutors.chat_dialog.new_conversation')}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChatDialog;