// src/components/dashboard/tabs/AITutors/CreateTutorDialog.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    CircularProgress,
    Typography,
    Alert,
    AlertTitle
} from '@mui/material';
import {
    Bot,
    Book,
    Activity,
    Check
} from 'lucide-react';
import { createAITutor, fetchSandboxInstancesForTutor } from '../../../services/apiService';

const CreateTutorDialog = ({ open, onClose, onSubmit, token, setError }) => {
    const { t } = useTranslation();

    // Состояния формы
    const [formData, setFormData] = useState({
        name: '',
        instructions: '',
        instruction_type: 'assistant',
        sandbox_instance: null
    });

    // Состояния UI
    const [loading, setLoading] = useState(false);
    const [sandboxInstances, setSandboxInstances] = useState([]);
    const [loadingSandboxes, setLoadingSandboxes] = useState(false);
    const [formError, setFormError] = useState('');

    // Загрузка списка инстансов песочниц
    useEffect(() => {
        if (open) {
            loadSandboxInstances();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, token]);

    const loadSandboxInstances = async () => {
        setLoadingSandboxes(true);
        try {
            const instances = await fetchSandboxInstancesForTutor(token);
            setSandboxInstances(instances);
        } catch (err) {
            console.error('Error loading sandbox instances:', err);
            setError(t('dashboard.ai_tutors.error.loading_sandboxes'));
        } finally {
            setLoadingSandboxes(false);
        }
    };

    // Обработчик изменения полей формы
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Валидация формы
    const validateForm = () => {
        if (!formData.name.trim()) {
            setFormError(t('dashboard.ai_tutors.error.name_required'));
            return false;
        }
        if (!formData.instructions.trim()) {
            setFormError(t('dashboard.ai_tutors.error.instructions_required'));
            return false;
        }
        return true;
    };

    // Обработчик отправки формы
    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);
        setFormError('');

        try {
            // Подготовка данных для отправки
            const tutorData = {
                ...formData,
                // Преобразуем пустую строку в null для sandbox_instance
                sandbox_instance: formData.sandbox_instance || null
            };

            // Создаем нового тьютора
            const newTutor = await createAITutor(token, tutorData);

            // Вызываем колбэк с новым тьютором
            onSubmit(newTutor);

            // Закрываем диалог
            handleClose();
        } catch (err) {
            console.error('Error creating AI Tutor:', err);
            setFormError(t('dashboard.ai_tutors.error.creating'));
            setError(t('dashboard.ai_tutors.error.creating'));
        } finally {
            setLoading(false);
        }
    };

    // Обработчик закрытия диалога
    const handleClose = () => {
        if (!loading) {
            setFormData({
                name: '',
                instructions: '',
                instruction_type: 'assistant',
                sandbox_instance: null
            });
            setFormError('');
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle className="flex items-center gap-2">
                <Bot className="text-purple-500" />
                {t('dashboard.ai_tutors.create_dialog.title')}
            </DialogTitle>

            <DialogContent>
                {formError && (
                    <Alert severity="error" className="mb-4">
                        <AlertTitle>{t('dashboard.ai_tutors.error.title')}</AlertTitle>
                        {formError}
                    </Alert>
                )}

                <div className="space-y-4 mt-2">
                    {/* Имя тьютора */}
                    <TextField
                        name="name"
                        label={t('dashboard.ai_tutors.create_dialog.name')}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />

                    {/* Тип тьютора */}
                    <FormControl component="fieldset" className="mb-4">
                        <FormLabel component="legend">
                            {t('dashboard.ai_tutors.create_dialog.type')}
                        </FormLabel>
                        <RadioGroup
                            name="instruction_type"
                            value={formData.instruction_type}
                            onChange={handleInputChange}
                            row
                        >
                            <FormControlLabel
                                value="assistant"
                                control={<Radio />}
                                label={
                                    <div className="flex items-center gap-2">
                                        <Book className="text-blue-500 w-4 h-4" />
                                        {t('dashboard.ai_tutors.type.assistant')}
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="feedback"
                                control={<Radio />}
                                label={
                                    <div className="flex items-center gap-2">
                                        <Activity className="text-green-500 w-4 h-4" />
                                        {t('dashboard.ai_tutors.type.feedback')}
                                    </div>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* Инструкции */}
                    <TextField
                        name="instructions"
                        label={
                            formData.instruction_type === 'feedback'
                                ? t('dashboard.ai_tutors.create_dialog.feedback_instructions')
                                : t('dashboard.ai_tutors.create_dialog.assistant_instructions')
                        }
                        value={formData.instructions}
                        onChange={handleInputChange}
                        multiline
                        rows={6}
                        fullWidth
                        required
                        placeholder={
                            formData.instruction_type === 'feedback'
                                ? t('dashboard.ai_tutors.create_dialog.feedback_placeholder')
                                : t('dashboard.ai_tutors.create_dialog.assistant_placeholder')
                        }
                    />

                    {/* Выбор песочницы */}
                    <div className="mt-4">
                        <FormControl fullWidth>
                            <FormLabel component="legend" className="mb-2">
                                {t('dashboard.ai_tutors.create_dialog.sandbox')}
                            </FormLabel>
                            {loadingSandboxes ? (
                                <div className="flex items-center gap-2 text-gray-500">
                                    <CircularProgress size={16} />
                                    <Typography variant="body2">
                                        {t('dashboard.ai_tutors.create_dialog.loading_sandboxes')}
                                    </Typography>
                                </div>
                            ) : (
                                <TextField
                                    select
                                    name="sandbox_instance"
                                    value={formData.sandbox_instance || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText={t('dashboard.ai_tutors.create_dialog.sandbox_helper')}
                                >
                                    <option value="">
                                        {t('dashboard.ai_tutors.create_dialog.no_sandbox')}
                                    </option>
                                    {sandboxInstances.map((instance) => (
                                        <option key={instance.id} value={instance.id}>
                                            {instance.sandbox ? `${instance.sandbox} (${instance.namespace})` : instance.namespace}
                                        </option>
                                    ))}
                                </TextField>
                            )}
                        </FormControl>
                    </div>

                    <Typography variant="body2" color="textSecondary" className="mt-4">
                        {t('dashboard.ai_tutors.create_dialog.note')}
                    </Typography>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    disabled={loading}
                >
                    {t('dashboard.ai_tutors.create_dialog.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="bg-purple-600 hover:bg-purple-700"
                    startIcon={loading ? <CircularProgress size={20} /> : <Check />}
                >
                    {loading
                        ? t('dashboard.ai_tutors.create_dialog.creating')
                        : t('dashboard.ai_tutors.create_dialog.create')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTutorDialog;