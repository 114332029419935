// src/components/dashboard/tabs/AITutors/TutorCard.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Button,
    Typography,
    CircularProgress,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Chip
} from '@mui/material';
import {
    Bot,
    Edit,
    Trash2,
    MessageSquare,
    FileText,
    Server,
    Activity,
    Book
} from 'lucide-react';
import { deleteAITutor } from '../../../services/apiService';
import TutorDetailsDialog from './TutorDetailsDialog';
import ChatDialog from './ChatDialog';

const TutorCard = ({ tutor, token, onDelete, setError }) => {
    const { t } = useTranslation();

    // Состояния для диалогов
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [chatDialogOpen, setChatDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Определение типа тьютора для отображения
    const tutorType = tutor.instruction_type === 'feedback'
        ? 'feedback'
        : 'assistant';

    // Иконка в зависимости от типа тьютора
    const TutorIcon = tutorType === 'feedback'
        ? Activity
        : Book;

    // Цвет в зависимости от типа
    const typeColor = tutorType === 'feedback'
        ? 'bg-green-100 text-green-800'
        : 'bg-blue-100 text-blue-800';

    // Обработчик удаления тьютора
    const handleDeleteTutor = async () => {
        try {
            setIsDeleting(true);
            await deleteAITutor(token, tutor.id);
            setIsDeleting(false);
            setDeleteDialogOpen(false);

            if (onDelete) {
                onDelete(tutor.id);
            }
        } catch (err) {
            setIsDeleting(false);
            setError(t('dashboard.ai_tutors.error.deleting'));
            console.error('Error deleting AI Tutor:', err);
        }
    };

    // Отображение числа материалов
    const materialsCount = tutor.materials?.length || 0;

    return (
        <div className="bg-white p-4 rounded-lg border border-gray-200 hover:border-purple-300 transition-all shadow-sm">
            <div className="flex justify-between items-start">
                <div>
                    <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
                        <Bot className="text-purple-500 w-5 h-5" />
                        {tutor.name}
                    </h3>
                    <div className="mt-2 flex items-center gap-2">
                        <Chip
                            label={t(`dashboard.ai_tutors.type.${tutorType}`)}
                            size="small"
                            className={typeColor}
                            icon={<TutorIcon className="w-3.5 h-3.5" />}
                        />

                        {materialsCount > 0 && (
                            <Chip
                                label={t('dashboard.ai_tutors.materials_count', { count: materialsCount })}
                                size="small"
                                className="bg-gray-100 text-gray-700"
                                icon={<FileText className="w-3.5 h-3.5" />}
                            />
                        )}

                        {tutor.sandbox_instance && (
                            <Chip
                                label={t('dashboard.ai_tutors.has_sandbox')}
                                size="small"
                                className="bg-blue-50 text-blue-700"
                                icon={<Server className="w-3.5 h-3.5" />}
                            />
                        )}
                    </div>
                </div>

                <div className="flex">
                    <Tooltip title={t('dashboard.ai_tutors.edit')}>
                        <IconButton
                            onClick={() => setDetailsDialogOpen(true)}
                            size="small"
                            className="text-blue-600"
                        >
                            <Edit className="w-4 h-4" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t('dashboard.ai_tutors.delete')}>
                        <IconButton
                            onClick={() => setDeleteDialogOpen(true)}
                            size="small"
                            className="text-red-600"
                        >
                            <Trash2 className="w-4 h-4" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {/* Краткое описание, если есть */}
            {tutor.instructions && (
                <div className="mt-3">
                    <Typography className="text-sm text-gray-600 line-clamp-2">
                        {tutor.instructions.substring(0, 120)}
                        {tutor.instructions.length > 120 ? '...' : ''}
                    </Typography>
                </div>
            )}

            {/* Кнопки действий */}
            <div className="mt-4 flex justify-end">
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<MessageSquare className="w-4 h-4" />}
                    onClick={() => setChatDialogOpen(true)}
                    className="text-purple-600 border-purple-300 hover:bg-purple-50"
                >
                    {t('dashboard.ai_tutors.chat')}
                </Button>
            </div>

            {/* Диалог подтверждения удаления */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => !isDeleting && setDeleteDialogOpen(false)}
            >
                <DialogTitle className="text-red-600">
                    {t('dashboard.ai_tutors.delete_dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('dashboard.ai_tutors.delete_dialog.message', { name: tutor.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        disabled={isDeleting}
                    >
                        {t('dashboard.ai_tutors.delete_dialog.cancel')}
                    </Button>
                    <Button
                        onClick={handleDeleteTutor}
                        color="error"
                        variant="contained"
                        disabled={isDeleting}
                    >
                        {isDeleting ? (
                            <>
                                <CircularProgress size={20} className="mr-2" />
                                {t('dashboard.ai_tutors.delete_dialog.deleting')}
                            </>
                        ) : (
                            t('dashboard.ai_tutors.delete_dialog.confirm')
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог деталей тьютора */}
            {detailsDialogOpen && (
                <TutorDetailsDialog
                    open={detailsDialogOpen}
                    onClose={() => setDetailsDialogOpen(false)}
                    tutor={tutor}
                    token={token}
                    setError={setError}
                    onUpdate={() => {
                        setDetailsDialogOpen(false);
                    }}
                />
            )}

            {/* Диалог чата с тьютором */}
            {chatDialogOpen && (
                <ChatDialog
                    open={chatDialogOpen}
                    onClose={() => setChatDialogOpen(false)}
                    tutor={tutor}
                    token={token}
                    setError={setError}
                />
            )}
        </div>
    );
};

export default TutorCard;